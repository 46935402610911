import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Button,
  Table,
  Input,
  Spinner,
} from "reactstrap"
import classnames from "classnames"
import { useCart, useRemoveCart, useUpdateCartQty } from "../../../hooks"

const CartItem = ({ item }) => {
  const [quantity, setQuantity] = useState(item.quantity)
  const { removeCartItemHandler } = useRemoveCart()
  const { updateQtyHandler } = useUpdateCartQty()

  function removeItem() {
    removeCartItemHandler(item.productId)
  }

  function handleQuantity(evt) {
    const val = parseInt(evt.target.value)
    if (val && val > 0) {
      setQuantity(val)
      updateQtyHandler(item.productId, val)
    } else {
      setQuantity(1)
    }
  }

  return (
    <Row>
      <Col>
        {item.image && item.image.sourceUrl && (
          <img src={item.image.sourceUrl} alt={item.name} />
        )}
      </Col>
      <Col>{item.name}</Col>
      <Col>{item.displayPrice ? item.displayPrice : null}</Col>
      <Col>
        <FormGroup>
          <Input
            type="number"
            name="quantity"
            id="cartItem"
            placeholder="with a placeholder"
            value={quantity}
            onChange={handleQuantity}
          />
        </FormGroup>
      </Col>
      <Col>
        <Button color="danger" onClick={removeItem}>
          <span className="lnr lnr-cross"></span>
        </Button>
      </Col>
    </Row>
  )
}

const CartItems = () => {
  const {
    state: { items: bucket },
  } = useCart()

  return (
    <ListGroup>
      {bucket.map((item, i) => (
        <ListGroupItem key={i}>
          <CartItem item={item} />
        </ListGroupItem>
      ))}
    </ListGroup>
  )
}

// const Coupon = () => {
//   return (
//     <InputGroup>
//       <Input placeholder="Enter Coupon" />
//       <InputGroupAddon addonType="append">
//         <Button>Apply</Button>
//       </InputGroupAddon>
//     </InputGroup>
//   )
// }

// const orderPlaces = [
//   { id: "Aurora", name: "Aurora", address: "7330 W 88th Ave Unit J" },
//   { id: "Arvada", name: "Arvada", address: "7330 W 88th Ave Unit J" },
// ]

const Cart = () => {
  // const [ship, toggleShip] = useState(true)
  // const [shipLocation, setShipLocation] = useState("")
  // const [dropdown, toggleDropdown] = useState(false)
  const {
    state: { items: bucket, subTotal },
  } = useCart()

  // const handleLocationClick = loc => {
  //   setShipLocation(loc)
  //   toggleShip(false)
  //   toggleDropdown(dropdown => !dropdown)
  // }

  return (
    <div>
      <h1 className="section-header">
        {bucket?.length > 0 ? "Your Cart" : "Your cart is empty"}
      </h1>
      {bucket?.length > 0 && (
        <div>
          <Row>
            <Col>
              <p>You have {bucket.length} items selected</p>{" "}
            </Col>
            <hr></hr>
          </Row>
          <Row>
            <Col sm="8">
              <CartItems />
            </Col>
            <Col sm="4">
              <Table bordered>
                <tbody>
                  {/* <tr>
                      <td>
                        <Coupon />
                      </td>
                    </tr> */}
                  <tr>
                    <td>
                      <span>
                        <b>Subtotal</b>
                      </span>
                      <div>
                        <p>${subTotal}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        <b>Tax</b>
                      </span>
                      <div>
                        <p>Determined at Checkout</p>
                      </div>
                    </td>
                  </tr>
                  {/* <tr>
                      <td>
                        <Label>Choose order method</Label>
                        <div>
                          <ButtonGroup>
                            <Button onClick={() => toggleShip(true)}>Ship</Button>
                            <ButtonDropdown
                              isOpen={dropdown}
                              toggle={() => toggleDropdown(dropdown => !dropdown)}
                            >
                              <DropdownToggle caret>
                                {shipLocation ? <b>{shipLocation}</b> : "Pickup"}
                              </DropdownToggle>
                              <DropdownMenu>
                                {orderPlaces.map(place => (
                                  <DropdownItem
                                    onClick={() => handleLocationClick(place.id)}
                                    key={place.name}
                                  >
                                    <div>
                                      <span>
                                        <b>{place.name}</b>
                                      </span>
                                      <p>{place.address}</p>
                                    </div>
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </ButtonDropdown>
                          </ButtonGroup>
                        </div>
                        <p></p>
                        <div>
                          {ship ? (
                            <>
                              <span>* Flat rate</span>
                              <p>
                                Items will be shipped to the addresss of your choice
                              </p>
                            </>
                          ) : (
                            <p>
                              Items are for pickup in{" "}
                              {shipLocation ? (
                                <b>{shipLocation}</b>
                              ) : (
                                "the selected location above"
                              )}
                            </p>
                          )}
                        </div>
                      </td>
                    </tr> */}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      )}
    </div>
  )
}

const CartForm = () => {
  const {
    state: { items: cartItems, totalValue },
  } = useCart()

  useEffect(() => {
    if (typeof fbq === `function`) {
      const content_ids = []
      const contents = []

      cartItems.forEach(item => {
        content_ids.push(item.id)
        contents.push({
          id: item.id,
          quantity: item.quantity,
        })
      })

      window.fbq("track", "InitiateCheckout", {
        content_ids: content_ids,
        contents: contents,
        currency: "USD",
        num_items: cartItems.length,
      })
    }
  }, [])

  const isCheckoutButtonEnabled =
    cartItems.length > 0 && cartItems.filter(item => !item.id).length === 0

  return (
    <>
      <Cart />
      {(cartItems?.length && (
        <Row>
          <Col>
            <div>
              <span>Total</span>
              <p style={{ fontSize: "20px" }}>
                <b>${totalValue}</b>
              </p>
            </div>
          </Col>
          <Col>
            <Button
              tag={Link}
              to={`/checkout`}
              color="primary"
              size="lg"
              disabled={!isCheckoutButtonEnabled}
            >
              {isCheckoutButtonEnabled && <span>Checkout</span>}
              {}
              <Spinner
                className={classnames({
                  "position-relative": true,
                  visible: !isCheckoutButtonEnabled,
                  invisible: isCheckoutButtonEnabled,
                })}
                size="sm"
                // type="grow"
              />
            </Button>
          </Col>
        </Row>
      )) || (
        <Row>
          <Link
            style={{ textAlign: "center", width: "100%", marginTop: "-30px" }}
            to="/"
          >
            Go back to home page
          </Link>
        </Row>
      )}
    </>
  )
}

export default CartForm
