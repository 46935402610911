import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CartForm from "../components/Interface/Cart/CartForm"
import { Container } from "reactstrap"

const CartPage = () => (
  <Layout location="checkout">
    <SEO title="Checkout" />
    <Container>
      <CartForm />
    </Container>
  </Layout>
)

export default CartPage
